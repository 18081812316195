<template>
    <!-- style="border-radius: 1%; background: rgb(255,255,255); background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(234,243,249,1) 100%)" -->
    <div class="p-1 mt-3" v-if="loggedIn && emailConfirmed" >
        <blue-background :height="500"/>
        <Home class="mt-2"/>
    </div>
    <HomeSecond v-else />
</template>

<script>
    import HomeSecond from '@/views/Pages/HomeSecond/HomeSecond.vue'
    import Home from '@/views/Pages/MyPortal/Home.vue'
    import BlueBackground from '@/views/Components/BlueBackground'

    export default {
        components:{
            HomeSecond,
            Home,
            BlueBackground
        },
        computed:{
            loggedIn() {
                return this.$store.getters['user/loggedIn']
            },
            emailConfirmed() {
                return this.$store.getters['user/emailConfirmed']
            }
        }
    }
</script>
        HomeSecond